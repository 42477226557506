// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import { AnagraficaDistributoreEdit } from "@/models/anafrafica/AnagraficaDistributore";
import { PartecipanteItem } from "@/models/ricercaGaranzie/partecipanteItem";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { Component, Watch } from "vue-property-decorator";
import gridComponentBase from "../../../components/grid/gridComponentBase.vue";
import { VNode } from "vue/types/umd";
import { EseguiComandoRequest } from "@/models/comandi/eseguiComandoRequest";
import permessi from "@/config/permessi";
import apiFile from "@/services/apiFile";
import helpers from "@/helpers";
import { Filter } from "@/models/grid/filter";

const _ENDPONT = api.endpoint.RICERCA_GARANZIE;

@Component({})
export default class ricercaGaranziePage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Vendite.GARANZIE];
	}
	showModalRecuperaPratica: boolean = false;
	idAdesioneDaRecuperare: string = "";
	listNoAdesioniCancellateRender: string = "noDataTemplate";
	adesioniCancellate: string[] = [];

	showModalSalvaPratiche: boolean = false;
	datePickerFooter = "Oggi - #=kendo.toString(data, 'dd/MM/yyyy') #";
	titleOfAction: string = "generico.rigeneraPraticheDataAcquisto"; // o --> generico.rigeneraPraticheInizioViaggio
	commandToDo = "SalvaPraticheDataAcquisto"; // o --> SalvaPratiche
	contrattoNumero: string = "";
	dataMinima: Date | null = null;
	invioMail: boolean = false;
	msgData: string = "generico.inserisciDataAcquisto"; // o --> generico.inserisciInizioViaggio
	showModalActions: boolean = false;

	get columns(): Array<any> {
		return [
			//{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },

			{ field: "userId", title: "UserId", width: 20, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "distributore", title: this.$i18n.t("generico.distributore").toString(), width: 110, hidden: false, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "utente", title: this.$i18n.t("generico.utente").toString(), width: 150, headerCell: "myHeaderCellTemplate", export: true },
			{
				field: "itemID",
				title: this.$i18n.t("generico.adesione").toString(),
				width: 80,
				hidden: false,
				format: "{0:#}",
				headerCell: "myHeaderCellTemplate",
				type: "numeric-int",
				headerType: "numeric-int", export: true
			},
			{ field: "numeroCompleto", title: this.$i18n.t("generico.numAdesione").toString(), width: 130, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "pacchetto", title: this.$i18n.t("generico.prodotto").toString(), headerCell: "myHeaderCellTemplate", export: true },
			{ field: "numeroPartecipanti", title: this.$i18n.t("generico.numeroPax").toString(), headerCell: "myHeaderCellTemplate", type: "numeric-int", headerType: "numeric-int", export: true },
			{
				field: "dataInserimento",
				title: this.$i18n.t("generico.dataAcquisto").toString(),
				format: "{0:dd/MM/yyyy}",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "date",
				headerType: "daterange", export: true,
				exportRenderFunction: this.exportRenderDate
			},
			{ field: "inizioViaggio", title: this.$i18n.t("generico.inizio").toString(), format: "{0:dd/MM/yyyy}", headerCell: "myHeaderCellTemplate", type: "date", headerType: "daterange", export: true, exportRenderFunction: this.exportRenderDate },
			{ field: "fineViaggio", title: this.$i18n.t("generico.fine").toString(), format: "{0:dd/MM/yyyy}", headerCell: "myHeaderCellTemplate", type: "date", headerType: "daterange", export: true, exportRenderFunction: this.exportRenderDate },
			{ field: "destinazione", title: this.$i18n.t("generico.destinazione").toString(), width: 120, hidden: false, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "importoViaggio", title: this.$i18n.t("generico.importo").toString(), format: "{0:n2}", headerCell: "myHeaderCellTemplate", headerType: "numeric", export: true,
			type: "renderfunction",
			renderFunction: this.renderCellImportoViaggio,
			exportRenderFunction: this.renderCellImportoViaggio,
		},

			{
				field: "partecipantiElenco",
				title: this.$i18n.tc("generico.partecipante_i", 2).toString(),
				width: 90,
				type: "renderfunction",
				renderFunction: this.renderCellPartecipantiElenco,
				headerCell: "myHeaderCellTemplate", export: true,
				exportRenderFunction: this.exportRenderPartecipanti,
				exportWidth: 600
			},
			{ field: "praticheComplementari", title: this.$i18n.t("generico.complementari").toString(), width: 90, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "note", title: this.$i18n.t("generico.note").toString(), width: 90, hidden: true, headerCell: "myHeaderCellTemplate", export: true },

			{ field: "riferimentoPratica", title: this.$i18n.t("generico.riferimento").toString(), width: 90, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "idxml", title: "XML ID".toString(), width: 90, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "datiAggiuntivi", title: "DatiAggiuntivi", width: 90, hidden: true, headerCell: "myHeaderCellTemplate" },

			{ field: "totalePremio", title: this.$i18n.t("generico.premio").toString(), format: "{0:n2}", width: 80, headerCell: "myHeaderCellTemplate", type: "numeric", headerType: "numeric", export: true },
			{
				field: "totalePremioNetto",
				title: this.$i18n.t("generico.premioNetto").toString(),
				hidden: true,
				format: "{0:n2}",
				width: 80,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				headerType: "numeric", export: true
			},
			{
				field: "totalePremioImposte",
				title: this.$i18n.t("generico.imposte").toString(),
				hidden: true,
				format: "{0:n2}",
				width: 80,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				headerType: "numeric", export: true
			},
			{
				field: "totalePremioPersona",
				title: this.$i18n.t("generico.premioXpersona").toString(),
				hidden: true,
				format: "{0:n2}",
				width: 80,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				headerType: "numeric", export: true
			},
			{
				field: "totalePremioPersonaImposte",
				title: this.$i18n.t("generico.imposteXpersona").toString(),
				hidden: true,
				format: "{0:n2}",
				width: 80,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				headerType: "numeric", export: true
			},
			{
				field: "commissioni",
				title: this.$i18n.t("generico.commissioni").toString(),
				hidden: false,
				format: "{0:n2}",
				width: 80,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				headerType: "numeric", export: true
			},
			{
				field: "totalePremioDaPagare",
				title: this.$i18n.t("generico.daPagare").toString(),
				hidden: false,
				format: "{0:n2}",
				width: 80,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				headerType: "numeric", export: true
			},

			{ field: "imageElenco", title: "ImageElenco", width: 0, hidden: true, headerCell: "myHeaderCellTemplate" },

			{ field: "contrattoNumero", title: this.$i18n.t("generico.contratto").toString(), width: 90, headerCell: "myHeaderCellTemplate", export: true },
			{
				field: "itemGUID",
				title: "PDFCopertura",
				label: this.$i18n.t("generico.certificatoAssicurativo").toString(),
				width: 80,
				hidden: false,
				editable: false,
				type: "download-file",
				downloadFileUrlType: "adesione",
				// template: function (dataItem) {
				// 	if (dataItem.PDFCopertura) return "<a href='" + dataItem.PDFCopertura + "'>Copertura</a>";
				// 	else return "";
				// },
				headerCell: "myHeaderCellTemplate"
			},

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	get columnsMobile(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "distributore", title: this.$i18n.t("generico.distributore").toString(), width: 110, hidden: false, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "numeroCompleto", title: "Adesione", width: 130, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "pacchetto", title: this.$i18n.t("generico.prodotto").toString(), headerCell: "myHeaderCellTemplate", export: true, width: 150 },
			{
				field: "partecipantiElenco",
				title: this.$i18n.tc("generico.partecipante_i", 2).toString(),
				width: 90,
				type: "renderfunction",
				renderFunction: this.renderCellPartecipantiElenco,
				headerCell: "myHeaderCellTemplate", export: true,
				exportRenderFunction: this.exportRenderPartecipanti,
				exportWidth: 600
			},
			{
				field: "istanteInserimento",
				title: "Data Acquisto",
				format: "{0:dd/MM/yyyy}",
				width: 150,
				headerCell: "myHeaderCellTemplate",
				type: "date",
				headerType: "daterange",
				export: true,
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "inizioViaggio",
				title: "Inizio",
				format: "{0:dd/MM/yyyy}",
				width: 120,
				headerCell: "myHeaderCellTemplate",
				type: "date",
				headerType: "daterange",
				export: true,
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "fineViaggio",
				title: "Fine",
				format: "{0:dd/MM/yyyy}",
				width: 120,
				headerCell: "myHeaderCellTemplate",
				type: "date",
				headerType: "daterange",
				export: true,
				exportRenderFunction: this.exportRenderDate
			},
			{ field: "destinazione", title: this.$i18n.t("generico.destinazione").toString(), width: 120, hidden: false, headerCell: "myHeaderCellTemplate", export: true },
			{
				field: "importoViaggio",
				title: "Importo",
				format: "{0:n2}",
				width: 90,
				headerCell: "myHeaderCellTemplate",
				headerType: "numeric",
				export: true,
				type: "renderfunction",
				renderFunction: this.renderCellImportoViaggio,
				exportRenderFunction: this.renderCellImportoViaggio
			},
			{ field: "totalePremio", title: "Premio", format: "{0:n2}", width: 80, headerCell: "myHeaderCellTemplate", type: "numeric", headerType: "numeric", export: true }
		];
	}
	
	//sort: any = [{ field: "dataInserimento", dir: "desc" }];
	sort: any = [{ field: "itemID", dir: "desc" }];

	created() {
		if (!(this.isAppMobile == false)) {
			this.pageable["buttonCount"] = 3;
			this.pageable["info"] = false;
			this.pageable["pageSizes"] = undefined;
		}
	}

	mounted() {
		var self = this;
		var partecipantiFilter = function(e) {
			if(e.filterCompareSelected === "contains"){
				var filtri = e.filterText.split(" ");
				for(var i = 0; i < filtri.length; i++){
					if( filtri[i] ){
						self.filter.filters.push({
							field: e.field,
							operator: e.filterCompareSelected,
							value: filtri[i] 
						});
					}
				}
			}
			else{
				self.filter.filters.push({
					field: e.field,
					operator: e.filterCompareSelected,
					value: e.filterText
				});
			}
		};
		
		this.customFilters["partecipantiElenco"] = partecipantiFilter;
	
		this.getData();
	}

	@Watch("idAdesioneDaRecuperare")
	onIdAdesioneDaRecuperareChange(newValue) {
		var self = this;
		if (this.idAdesioneDaRecuperare.length > 2) {
			api.searchForStringAutocomplete(_ENDPONT, this.idAdesioneDaRecuperare)
				.then(res => {
					self.adesioniCancellate = res;
				})
				.catch(err => {
					self.adesioniCancellate = [];
				});
		}
	}

	renderCellPartecipantiElenco(data: string) {
		var array = JSON.parse(data) as PartecipanteItem[];
		let innerHtml = data;
		if (array && array.length > 0) {
			innerHtml = array
				.map(function(item) {
					return `${item.Cognome} ${item.Nome} `;
				})
				.join(", ");
		}
		var result = `<div style='display:block; overflow:hidden;max-height:30px;'>${innerHtml}</div>`;

		return result;
	}

	exportRenderPartecipanti(data: string) {
		var array = JSON.parse(data) as PartecipanteItem[];
		let innerHtml = data;
		if (array && array.length > 0) {
			innerHtml = array
				.map(function(item) {
					return `${item.Cognome} ${item.Nome} `;
				})
				.join(", ");
		}
		return innerHtml;
	}

	
	renderCellImportoViaggio(data: number, item: any) {
		if(item.secondoImporto && item.secondoImporto > 0)
			return kendo.toString(item.secondoImporto, "n2")
		return kendo.toString(data || 0, "n2");
	}

	getPartecipanti(data: string) {
		var array = JSON.parse(data) as PartecipanteItem[];
		let partecipanti: string[] = [];
		if (array && array.length > 0) {
			partecipanti = array.map(function(item) {
				return `${item.Cognome} ${item.Nome}`;
			});
		}
		return partecipanti;
	}

	getTestoTipoApplicazione(data: any){
		return data.secondoImporto ? helpers.getTipoApplicazioneLabelAlloggi( data.tipoApplicazione) : "Alloggi";
	}

	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	getData() {
		var self = this;
		this.getGridData(_ENDPONT);
	}
	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<strong>${item.itemID}</strong><h5>${item.utente}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.adesione")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	onEdit(item: any) {
		// console.log(process.env.VUE_APP_LINK_CARRELLO);
		console.log(this.$appConfig.carrelloUrl);
		this.$router.push({ path: `modifica-pratica/${item.itemID}` });
	}

	onShowRecuperaPraticaModal() {
		this.idAdesioneDaRecuperare = "";
		this.showModalRecuperaPratica = true;
	}

	annullaRecuperoAdesione() {
		this.idAdesioneDaRecuperare = "";
		this.showModalRecuperaPratica = false;
	}

	recuperaAdesione() {
		var self = this;
		api.recuperaPraticaCancellata(_ENDPONT, this.idAdesioneDaRecuperare)
			.then(res => {
				this.$bvToast.toast(this.$i18n.t("msg.praticaRipristinata").toString(), {
					variant: "success",
					title: this.$i18n.t("msg.ripristinoPratica").toString(),
					solid: true
				});

				self.getData();
				self.idAdesioneDaRecuperare = "";
				self.showModalRecuperaPratica = false;
			})
			.catch(err => {
				if (((err || {}).response || {}).status != 403) {
					this.$bvToast.toast(this.$i18n.t("msg.erroreRipristinoPratica").toString(), {
						variant: "danger",
						title: this.$i18n.t("generico.errore").toString(),
						solid: true
					});
				}
			});
	}

	onShowSalvaPratiche(type: number) {
		//type: 0 = DataAcquisto / 1 = Data Inizio Viaggio
		this.dataMinima = null;
		this.contrattoNumero = "";
		this.invioMail = false;

		if (type == 0) {
			this.commandToDo = "SalvaPraticheDataAcquisto";
			this.titleOfAction = "generico.rigeneraPraticheDataAcquisto";
			this.msgData = "generico.inserisciDataAcquisto";
		} else {
			this.commandToDo = "SalvaPratiche";
			this.titleOfAction = "generico.rigeneraPraticheInizioViaggio";
			this.msgData = "generico.inserisciInizioViaggio";
		}

		this.showModalSalvaPratiche = true;
	}

	annullaSalvaPratiche() {
		this.dataMinima = null;
		this.contrattoNumero = "";
		this.invioMail = false;

		this.showModalSalvaPratiche = false;
	}

	salvaPratichePerDataAcquisto() {
		if (!this.contrattoNumero || !this.dataMinima) {
			this.$bvToast.toast(this.$i18n.t("generico.riempireTuttiICampi").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});

			return;
		}
		var self = this;
		let data: EseguiComandoRequest = {
			comando: this.commandToDo,
			elenco: [],
			parametri: [this.contrattoNumero, this.invioMail ? 1 : 0, this.dataMinima]
		};
		api.eseguiComando(data)
			.then(res => {
				self.$bvToast.toast(self.$i18n.t("generico.cmdeseguito").toString(), {
					variant: "success",
					title: self.$i18n.t("generico.azioneeseguita").toString(),
					solid: true
				});

				self.dataMinima = null;
				self.contrattoNumero = "";
				self.invioMail = false;

				self.showModalSalvaPratiche = false;
				self.getData();
			})
			.catch(err => {
				self.$bvToast.toast(self.$i18n.t("generico.erroreGenerico").toString(), {
					variant: "error",
					title: self.$i18n.t("generico.errore").toString(),
					solid: true
				});
			});
	}
	downloadAdesioneFile(guid) {
		var self = this;
		var url = `adesione/${guid}`;
		var nomeFile = "CertificatoAssicurativo.pdf";
		apiFile
			.downloadFile(url, nomeFile)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/pdf" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = nomeFile;
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}

	downloadFileImmaginePartecipanti(path) {
		var self = this;
		var url = `imgPartecipanti?name=${path}`;
		var nomeFile = path.substring(path.lastIndexOf('/') + 1);
		apiFile
			.downloadFile(url, "")
			.then(response => {
				const blob = new Blob([response.data], { type: response.headers['content-type'] });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = nomeFile;
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}

	getUlterioriDatiValore(item){
		return helpers.getUlterioriDatiValore(item);
	}

	getDatiAggiuntivi(item) {
		return item.datiAggiuntivi ? JSON.parse(atob(item.datiAggiuntivi)) : null
	}

	expandChangeCustom(dataItem){
		var self = this;
		if( !dataItem.dettaglio){
			api.get(`${_ENDPONT}/adesione/${dataItem.itemID}`).then(res =>{
				dataItem.dettaglio = res
				self.expandChange(dataItem);
			})
		}
		else
			self.expandChange(dataItem);

	}

	showfilterDialog: boolean = false;

	onApplyFiltersMobile(filtersMobile: Filter) {
		this.filter = filtersMobile;
		this.onFilterMobileApply();
		this.onCloseDialogMessage();
	}
	onRemoveAllFilters() {
		this.clearSortAndFilter();
		//this.onCloseDialogMessage();
	}

	onCloseDialogMessage() {
		this.showfilterDialog = false;
	}

	onOpenFilterDialog() {
		this.showfilterDialog = true;
	}
}
